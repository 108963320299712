import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Text, Box, Heading as InfoHeader, Flex } from "rebass/styled-components";
import {
  StyledHeaderBg,
  StyledContainer,
  ContentContainer,
  DataContent,
  DataItem,
  ItemLabel,
  ItemValue,
} from "./style";

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      csrBg: file(sourceInstanceName: { eq: "product" }, name: { eq: "detail" }) {
        childImageSharp {
          fluid(maxWidth: 2880) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Box mt={["58px"]} id="top">
      <StyledContainer>
        <StyledHeaderBg objectFit="contain" fluid={data.csrBg.childImageSharp.fluid}></StyledHeaderBg>
      </StyledContainer>
      <DataContent>
        <Flex justifyContent="center" width="100%" alignItems={"center"} flexWrap={"wrap"}>
          <Box width={["100%", "100%", "100%", "50%", "50%"]}>
            <DataItem>
              <ItemLabel>57K+</ItemLabel>
              <ItemValue>Undergraduated Students Reached in 2 Cities</ItemValue>
            </DataItem>
          </Box>

          <Box width={["100%", "100%", "100%", "50%", "50%"]}>
            <DataItem>
              <ItemLabel>2</ItemLabel>
              <ItemValue>Tertiary Institutions Reached in 2 Cities</ItemValue>
            </DataItem>
          </Box>
        </Flex>
      </DataContent>
    </Box>
  );
};

export default Header;
