import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Text, Box, Flex } from "rebass/styled-components";
import Fade from "react-reveal/Fade";
import { Content, Title, Info, Date } from "./style";
import { Container } from "../../../global";

import Banner from "../../../../images/csr/campus.png";

const OutOfSchool = () => {
  return (
    <Container
      position="relative"
      style={{ width: "100vw", maxWidth: "100vw", padding: "0", background: "rgb(244, 252, 250);" }}
    >
      <Content>
        <Title>Campus Upgrade</Title>
        <Info>
          OPay Campus Upgrade aims to carry out upgrades on identified infrastructure(s) that serves the general need of
          the campus community.
        </Info>
        <Flex
          justifyContent="center"
          marginTop={["20px", "20px", "20px", "50px", "50px"]}
          marginBottom={["20px", "20px", "20px", "50px", "50px"]}
          flexDirection={["column", "column", "column", "row", "row"]}
        >
          <Box maxWidth={["100%", "100%", "100%", "60%", "60%"]}>
            <img src={Banner}></img>
          </Box>
        </Flex>
        <Info>
          We firmly believe in the importance of giving back to the communities in which we operate. We understand that
          tertiary institutions play a crucial role in shaping the future of our society by educating and empowering the
          next generation of leaders, and we are eager to support your efforts. In so doing, federal tertiary
          institutions from across Nigeria will benefit structural upgrades through the{" "}
          <Date>
            <span>OPay Campus Upgrade Programme.</span>
          </Date>
        </Info>
      </Content>
    </Container>
  );
};

export default OutOfSchool;
